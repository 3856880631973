// playlistService.js

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchPlaylists = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/playlists`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching playlists');
        return await response.json();
    } catch (error) {
        console.error('fetchPlaylists error:', error);
        throw error;
    }
};

export const createPlaylist = async (playlistName) => {
    try {
        const response = await fetch(`${API_BASE_URL}/playlists`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ playlistName }),
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error creating playlist');
        fetchPlaylists();
        return await response.json();
    } catch (error) {
        console.error('createPlaylist error:', error);
        throw error;
    }
};

export const deletePlaylist = async (playlistId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/playlists/delete/${playlistId}/`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!response.ok) throw new Error('Error creating playlist');
  
        return await response.json();
    } catch (error) {
        console.error('createPlaylist error:', error);
        throw error;
    }
}

export const updatePlaylist = async (playlistId, songIds) => {
  console.log('Updating playlist with song IDs:', songIds); // Add this line

  try{
    const response = await fetch(`${API_BASE_URL}/playlists/${playlistId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ songIds }),
    })

    if (!response.ok) {
      throw new Error('Playlist update failed');
    }
  }
  catch (error){
    console.error('updatePlaylist error:', error);
    throw error;
  }


}



export const addSongToPlaylist = async (playlistId, songId) => {
    if (playlistId && songId) {
      try {
        const response = await fetch(`${API_BASE_URL}/playlists/${playlistId}/addSong`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ songId }),
          credentials: 'include',
        });
  
        if (response.ok) {
          const result = await response.json();
          console.log(result.message); // Song added to playlist successfully
          fetchPlaylists(); // Refresh the user's playlists
        } else {
          console.error('Failed to add song to playlist');
        }
      } catch (error) {
        console.error('Error adding song to playlist:', error);
      }
    } else {
      console.error('PlaylistId and SongId are required');
    }
  };


  export const removeSongFromPlaylist = async (playlistId, songId) => {
    if (playlistId && songId) {
      console.log(playlistId, songId);
      try {
        const response = await fetch(`${API_BASE_URL}/playlists/${playlistId}/removeSong`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ songId }),
          credentials: 'include',
        });
  
        if (response.ok) {
          const result = await response.json();
          console.log(result.message); // Song removed from playlist successfully
          fetchPlaylists(); // Refresh the user's playlists
        } else {
          console.error('Failed to remove song from playlist');
        }
      } catch (error) {
        console.error('Error removing song from playlist:', error);
      }
    } else {
      console.error('PlaylistId and SongId are required');
    }
  };

 export const handleCreatePlaylist = async () => {
   /*
    if (playlistName) {
      try {
        const response = await fetch('http://localhost:5000/api/playlists', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ playlistName }),
          credentials: 'include',
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result.message); // Playlist created successfully
          fetchPlaylists();

        } else {
          console.error('Failed to create playlist');
        }
      } catch (error) {
        console.error('Error creating playlist:', error);
      }
    }*/
  };
  
  export const fetchPlaylistDetails = async (playlistId) => {
    if (!playlistId) {
        console.error('Playlist ID is required');
        return;
    }

    try {
        const response = await fetch(`${API_BASE_URL}/playlists/${playlistId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching playlist details');
        return await response.json();
    } catch (error) {
        console.error('fetchPlaylistDetails error:', error);
        throw error;
    }
};

export const searchSongs = async (searchTerm) => {
  if (!searchTerm) {
      console.error('Search term is required');
      return [];
  }

  try {
      // Append searchTerm as a query parameter in the URL
      const url = new URL(`${API_BASE_URL}/songs/search`);
      url.searchParams.append('searchTerm', searchTerm);

      const response = await fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
      });

      if (!response.ok) throw new Error('Error searching songs');
      return await response.json();
  } catch (error) {
      console.error('searchSongs error:', error);
      throw error;
  }
};



// Add more functions for other playlist operations...
