import React from 'react';

const pricing = [
  { churches: "Engangsbrug", price: "X" },
  { churches: "Fri Månedlig", price: "X" },
  { churches: "Fri Årlig", price: "X" },
];

const PricingTiers = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600 pt-16 md:pt-24 ">
      <div class="px-4 md:px-0 text-center">
      <h1 className="text-4xl font-bold text-white mb-20 break-normal">Abonnementer til jeres behov</h1>
      <div className="flex flex-wrap justify-center gap-6 px-16">
        {pricing.map((plan, index) => (
          
          <div
            key={index}
            className={`mt-8 md:mt-0 relative justify-between flex flex-col justify-between bg-white shadow-xl rounded-lg p-6 px-8 w-80 text-center transform hover:scale-105 transition-transform duration-300 ${
              plan.churches === 1 ? 'transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0' : ''
            }`}
          >
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-4">{plan.churches} {plan.churches > 1 ? 'r' : ''}</h2>
            {plan.churches == "Engangsbrug" && <p className="text-2xl text-gray-700 mb-4">{plan.price},-</p>}
            {plan.churches == "Fri Månedlig" && <p className="text-2xl text-gray-700 mb-4">{plan.price},-/måned</p>}
            {plan.churches == "Fri Årlig" && <p className="text-2xl text-gray-700 mb-4">{plan.price},-/år</p>}


            
<ul class="space-y-4 text-left text-gray-500 dark:text-gray-400 mt-6">
    <li class="flex items-center space-x-3 rtl:space-x-reverse">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>

        {plan.churches === "Engangsbrug" && <span className="break-normal"><strong>Engangsbrug brug i 1 kirke</strong></span>}
                {plan.churches >= 2 && <span>Ubegrænset brug i <strong>{plan.churches} kirker</strong></span>}
                {plan.churches != "Engangsbrug" && <span>Ubegrænset brug i <strong>1 kirke</strong></span>}

    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse">
        <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Kundesupport via email</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse">
        <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Adgang til hjælpe-videoer</span>
    </li>
    {plan.churches >= 3 && <li class="flex items-center space-x-3 rtl:space-x-reverse">
        <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Forslag til musikalske tilføjelser</span>
    </li>}
    {plan.churches >= 4 && <li class="flex items-center space-x-3 rtl:space-x-reverse">
        <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Dialog om ny funktionalitet</span>
    </li>}
</ul>

           
           
          </div>
          <div clasName=""> <button className="w-auto mt-6 md:mt-8 bg-gradient-to-r from-green-400 to-blue-500 font-semibold text-white px-12 md:px-16 py-2 rounded-full hover:from-green-500 hover:to-blue-600 transition-colors duration-300">
          Vælg Plan
        </button> </div>
        </div>
         
        ))}
      </div>
      </div>
    </div>
  );
};

export default PricingTiers;
