import React from 'react';
import { Link } from 'react-router-dom';

const PlaylistItem = ({ playlist, deletePlaylist}) => {
    return (
      <Link to={`/playlist/${playlist._id}`} 
         className="flex items-center justify-center md:justify-start min-h-16 px-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
       <div class="lg:grid lg:grid-cols-3 md:text-left lg:justify-between md:w-full">
        <div className="lg:text-lg text-3xl flex flex-col items-center md:items-start justify-start w-full h-full leading-none py-4">
          <p class="font-semibold">{playlist.name}</p>
          <p className="italic text-2xl lg:text-base">Playliste</p>
        </div>
        <div className="hidden md:block lg:text-lg text-3xl flex flex-col items-center md:items-start justify-start w-full h-full leading-none py-4">
          <p>{playlist.name}</p>
          <p className="italic text-2xl lg:text-base">Kirke</p>
        </div>
        <div className="hidden md:grid md:grid-cols-2 lg:text-lg text-3xl flex flex-col items-center md:items-center justify-start w-full h-full leading-none py-4">
         <div> <p>{playlist.name}</p>
          <p className="italic text-2xl lg:text-base">Playliste</p>
          </div>
          <div class="justify-self-end pr-6">
          <button onClick={(e) => {
            console.log('Button clicked'); // Check if this logs when the button is clicked
            e.preventDefault(); // Prevent the default action.

            e.stopPropagation();
            deletePlaylist(playlist._id);
          }}>
            
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 hover:fill-red-500 stroke-red-500 ">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

          </button>
          </div>
        </div>
        </div>


      </Link>
    );
};

export default PlaylistItem;
