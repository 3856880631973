import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MyButton from '../ui/Button';
import { LargeInput, DefaultInput, SmallInput } from '../ui/TextField';
import PlaylistItem from './PlaylistItem';
import PlusIcon from '../ui/PlusIcon';
import PlaylistModal from './PlaylistModal';


const PlaylistManager = ({ playlists, onCreatePlaylist, onAddSongToPlaylist, onDeletePlaylist }) => {

  // STATES FROM USEPLAYLIST HOOK PASSED FROM APP.JS
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [playlistId, setPlaylistId] = useState('');
  const [songId, setSongId] = useState('');

  // LOCAL STATE
  const [showCreateForm, setShowCreateForm] = useState(false);


  const createPlaylist = () => {
    onCreatePlaylist(newPlaylistName);
    setNewPlaylistName('');
  };

  const addSong = () => {
    onAddSongToPlaylist(playlistId, songId);
    setPlaylistId('');
    setSongId('');
  };

  

  

  return (
    <div>
      <PlaylistModal isOpen={showCreateForm} onClose={() => setShowCreateForm(false)}>
        <div class="flex flex-col items-center justify-center">
          <h3 class="text-2xl lg:text-lg pb-2 font-semibold">Opret en ny playliste</h3>
          <DefaultInput 
            value={newPlaylistName} 
            onChange={(e) => setNewPlaylistName(e.target.value)} 
            placeholder="Navn på playliste" 
          />
          <MyButton onClick={(e) => {
            createPlaylist();
            setShowCreateForm(false);
          }}>Opret Playliste</MyButton>
        </div>
      </PlaylistModal>

     
      <div className="bg-gray-100 text-gray-900 min-h-screen p-4 flex justify-center md:px-32 xl:px-56 2xl:px-72">
  <div className="justify-center items-center w-full max-w-md md:max-w-full text-center bg-white border border-gray-300 rounded-lg shadow overflow-hidden dark:bg-gray-800 dark:border-gray-700">
    
    <header className="bg-gray-50 text-black text-3xl sm:text-2xl font-semibold py-4 rounded-t-lg dark:bg-purple-900 border-b-2 border-gray-300">
      Mine Playlister
    </header>
    
    <main className=" ">
      <div className="flex justify-between md:justify-start items-center md:mb-6 pb-12 p-6">
        <span className="text-2xl lg:text-lg font-medium md:pr-4">Opret Playliste</span>
        <PlusIcon onClick={() => setShowCreateForm(prev => !prev)} className="cursor-pointer hover:text-purple-300 dark:text-purple-300"/>
      </div>
      <div class="hidden md:grid md:grid-cols-3 justify-between px-4 pb-2 border-b-2">
        <div><p class="text-left">Navn</p></div>
        <div><p class="text-left">Lokation</p></div>
        <div><p class="text-left">Ansvarlig</p></div>
      </div>
      {playlists.map((playlist, index) => (
        <PlaylistItem key={index} playlist={playlist} deletePlaylist={onDeletePlaylist} className="hover:bg-purple-100 dark:hover:bg-purple-700"/>
      ))}

    </main>

  </div>
</div>



    </div>
  );
};

export default PlaylistManager;
