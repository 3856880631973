import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import orgelstreamImage from '../img/orgelstreamnotext.png';
import orgelstreamImageTwo from '../img/orgelstreamquart.png';
import MyButton from '../ui/Button'



const Header = ({ isLoggedIn, onLogout }) => {
 
    
    const [phoneMenuOpen, setPhoneMenuOpen] = useState(false);
    const togglePhoneDropdown = () => setPhoneMenuOpen(!phoneMenuOpen);
  

    const DropdownMenu = () => {
      const [isOpen, setIsOpen] = useState(false);

      
    
      // Function to toggle the dropdown's open state
      const toggleDropdown = () => setIsOpen(!isOpen);

      
    
      return (
        <div onMouseLeave={() => setIsOpen(false)} >
        <li className="relative group">
          <button onMouseEnter={toggleDropdown}  className="flex items-center text-gray-700 hover:text-gray-900 focus:outline-none" aria-haspopup="true" aria-expanded={isOpen}>
            Info
            {/* Arrow icon indicating dropdown */}
            <svg className={`ml-2 w-4 h-4 ${isOpen ? 'transform rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
          </button>
          {isOpen && (
            <div  onClick={toggleDropdown} className="absolute right-0 pt-2 w-48 bg-white shadow-xl rounded-md py-1 z-50" role="menu">
              <Link to="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Forside</Link>
              <Link to="/om-os" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Om os</Link>
              <Link to="/services" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Services</Link>
              <Link to="/kontakt" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Kontakt</Link>
              <Link to="/privatlivspolitik" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Privatlivspolitik</Link>

            </div>
          )}
        </li>
        </div>
      );
    };
  return (
    
    <header className="App-header">
      
<div class="">
<nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
  <Link to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
      <img src={orgelstreamImage} class="h-8" alt="OrgelStream logo"></img>
      <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">OrgelStream</span>
  </Link>
  <div class="flex md:order-2 space-x-3 md:space-x-6 rtl:space-x-reverse md:pr-4">
  


    <Link to="/hvordan">
  <button type="button" class="w-full relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-white rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 px-4 py-2">Hvordan?</button>
  </Link>
  <div class="hidden md:flex align-middle ring-2 ring-gray-300 dark:ring-gray-500 relative w-9 h-9 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 ">
    <Link to="/konto">
    <svg class="absolute w-11 h-11 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
</Link>
</div>
      <button onClick={togglePhoneDropdown} data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
        <span class="sr-only">Open main menu</span>

       {phoneMenuOpen && isLoggedIn && (       <div className="absolute top-full left-0 right-0 bg-white dark:bg-gray-900 shadow-md p-4 text-lg">
        <ul>
       <li>
        <Link to="/korsvar" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Korsvar</Link>
      </li>
      <li>
        <Link to="/preludium" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Præludier</Link>
      </li>
      <li>
        <Link to="/postludium" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Postludier</Link>
      </li>
      <li>
        <Link to="/salmer" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Salmer</Link>
      </li>
      <li>
        <Link to="/my-playlists" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Mine Playlister</Link>
      </li>
      <li>
        <button onClick={onLogout} class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Log Ud</button>
      </li></ul>
            {/* Place additional content or links here */}
          </div> )} 

          {phoneMenuOpen && !isLoggedIn && (       <div className="absolute top-full left-0 right-0 bg-white dark:bg-gray-900 shadow-md p-4 text-lg">
        <ul>
        <li><Link to="/">
      <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Hjem</span>
        </Link>
      </li>
      <li>
        <Link to="/om-os">
        <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Om os</span>
        </Link>
      </li>
      <li>
      <Link to="/services">
        <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</span>
        </Link> </li>
      <li>
      <Link to="/kontakt">
        <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Kontakt</span>
        </Link></li>
      <li>
        <Link to="/login" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Log på</Link>
      </li>
      <li>
        <Link to="/register" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Opret</Link>
      </li>
      </ul>
            {/* Place additional content or links here */}
          </div> )} 


        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
  </div>
 
  <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
    <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
     
      {isLoggedIn ? (
    <>
  

        <DropdownMenu/>
  
    <li>
        <Link to="/korsvar" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Korsvar</Link>
      </li>
      <li>
        <Link to="/preludium" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Præludier</Link>
      </li>
      <li>
        <Link to="/postludium" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Postludier</Link>
      </li>
      <li>
        <Link to="/salmer" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Salmer</Link>
      </li>
      <li>
        <Link to="/my-playlists" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Mine Playlister</Link>
      </li>
      <li>
        <button onClick={onLogout} class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Log Ud</button>
      </li>
      {/* More logged in links */}
    </>
) : (
    <>
     <li><Link to="/">
      <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Hjem</span>
        </Link>
      </li>
      <li>
        <Link to="/om-os">
        <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Om os</span>
        </Link>
      </li>
      <li>
      <Link to="/services">
        <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</span>
        </Link> </li>
      <li>
      <Link to="/kontakt">
        <span class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Kontakt</span>
        </Link></li>
      <li>
        <Link to="/login" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Log på</Link>
      </li>
      <li>
        <Link to="/register" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-700 md:p-0 md:dark:hover:text-purple-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Opret</Link>
      </li>
      {/* More guest links */}
    </>
)}

    </ul>
  </div>
  </div>
</nav>
{/* OLD NAVBAR
              <div class="mb-8">
      <img src={orgelstreamImageTwo} class="object-scale-down" alt="Image Content"></img>
      {isLoggedIn ? (
        // Display this when the user is logged in
        <div>
          <button onClick={onLogout}>Log Out</button>
          <Link className="test" to="/">Hjem</Link>
          <Link to="/my-playlists">My Playlists</Link>
          {/* Add more links or content for logged in users here }
        </div>
      ) : (
        // Display this when the user is not logged in
        <div>
                      <Link className="test" to="/">Hjem</Link>
          <Link className="test" to="/login">Login</Link>
          <Link className="test" to="/register">Register</Link>
          {/* Add more links or content for guests here }
        </div>
      )}
              </div>
      */}
      </div>
    </header>
  );
};

export default Header;
