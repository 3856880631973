import { useState, useEffect } from 'react';
import { login, register, logout, checkUserSession } from '../services/authService';

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginMessage, setLoginMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const status = await checkUserSession();
      setIsLoggedIn(status); // Set the login status
      setIsLoading(false);   // Set loading to false after status is set
    };

    initializeAuth();
  }, []);

  const handleLogin = async (email, password) => {
    try {
        const result = await login(email, password);
        setIsLoggedIn(true);
        console.log('Logged in');
        setLoginMessage('');
    } catch (error) {
        console.error('Login error: ', error);
        setLoginMessage('Login error: '+error.message);
    }
  };

  const handleLogout = async () => {
    try {
        await logout();
        setIsLoggedIn(false);
    } catch (error) {
        console.error('Logout error:', error);
    }
  };

  const handleRegister = async (email, password) => {
    try {
        await register(email, password);
        console.log('Account created');
        await login(email, password);
        setIsLoggedIn(true);
    } catch (error) {
        console.error('Registration error: ', error.message);
    }
  };

  return { isLoggedIn, isLoading, loginMessage, handleLogin, handleLogout, handleRegister };
};
