// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* audioPlayerOverrides.css */

/* Overriding the button color */
.rhap_container svg {
    color: white;
}

.rhap_time {
    color:white
}
  `, "",{"version":3,"sources":["webpack://./src/audioPlayerOverridden.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B,gCAAgC;AAChC;IACI,YAAY;AAChB;;AAEA;IACI;AACJ","sourcesContent":["/* audioPlayerOverrides.css */\n\n/* Overriding the button color */\n.rhap_container svg {\n    color: white;\n}\n\n.rhap_time {\n    color:white\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
