import React from 'react';

const SongItem = ({ song }) => {
  return (
    <div className="song-item">
      <div className="song-details">
        <div className="song-name">{song.title}</div>
        <div className="song-id">{song.artist}</div>
        {/* Include more details if available */}
      </div>
      {/* Optional: Add interactive elements like play button */}
      {/* <button className="play-button">Play</button> */}
    </div>
  );
};

export default SongItem;
