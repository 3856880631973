const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUser = async() => {
    try {
        const response = await fetch(`${API_BASE_URL}/user/:userid`, {
            method: 'GET',
            credentials: 'include',
        });
        return await response.json();
    }
    catch (error) {
        console.error('getUser error:', error);
        throw error;
    }
}

export const createKirker = async(kirke) => {
    try {
        const response = await fetch(`${API_BASE_URL}/kirker`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(kirke),
            credentials: 'include',
        });
        return await response.json();
    }
    catch (error) {
        console.error('createKirker error:', error);
        throw error;
    }

}

export const fetchKirker = async() => {
    try {
        const response = await fetch(`${API_BASE_URL}/kirker`, {
            method: 'GET',
            credentials: 'include',
        });
        return await response.json();
    }
    catch (error) {
        console.error('getKirker error:', error);
        throw error;
    }
}