import { useState, useEffect } from 'react';
import { fetchAllKorsvar, fetchAllPostludium, fetchAllPreludium, fetchAllSalmer } from '../services/songService';

export const useSong = (isLoggedIn) => {
  const [salmer, setSalmer] = useState([]);
  const [korsvar, setKorsvar] = useState([]);
  const [preludium, setPreludium] = useState([]);
  const [postludium, setPostludium] = useState([]);
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    if (isLoggedIn) {
      handleGetAllKorsvar();
      handleGetAllPostludium();
      handleGetAllPreludium();
      handleGetAllSalmer();
    }
  }, [isLoggedIn]);

  const fetchSongFromPlaylist = async () => {
    try {
      const data = await fetchSongFromPlaylist();
      setSongs(data.songs);
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

 

  const handleGetAllKorsvar = async () => {
    try {
      const data = await fetchAllKorsvar();
      console.log(data);
      setKorsvar(data);
    } catch (error) {
      console.error('Error fetching korsvar:', error);
    }
  }

  
  const handleGetAllPostludium = async () => {
    try {
      const data = await fetchAllPostludium();
      console.log(data);
      setPostludium(data);
    } catch (error) {
      console.error('Error fetching postludium:', error);
    }
  }

  
  const handleGetAllPreludium = async () => {
    try {
      const data = await fetchAllPreludium();
      console.log(data);
      setPreludium(data);
    } catch (error) {
      console.error('Error fetching preludium:', error);
    }
  }

  
  const handleGetAllSalmer = async () => {
    try {
      const data = await fetchAllSalmer();
      console.log(data);
      setSalmer(data);
    } catch (error) {
      console.error('Error fetching salmer:', error);
    }
  }



  return { salmer, korsvar, preludium, postludium, handleGetAllKorsvar, handleGetAllSalmer, handleGetAllPreludium, handleGetAllPostludium};
};
