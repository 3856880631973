import React from 'react';
import { Link } from 'react-router-dom';

const AboutUs = () => {return (
   

<div class="bg-gray-50 py-8 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-12">
  <div class="relative max-w-xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl text-gray-900">
Vores Mission      </h2>
      <p class="mt-4 text-lg leading-6 text-gray-500">
    I en tid, hvor adgangen til live orgelmusik kan være begrænset, stræber Kirkeorglet efter at fylde dette tomrum ved at tilbyde indspillet orgelmusik af høj kvalitet. Vores mål er at give kirker muligheden for at bibeholde den værdifulde orgelmusikalske tradition i deres ceremonier, uanset geografiske eller personalemæssige begrænsninger. Med Kirkeorglet er det nu muligt at streame orgelmusik direkte til gudstjenester, bryllupper, begravelser og andre kirkelige begivenheder, hvilket sikrer, at den åndelige og kulturelle værdi af orgelmusik bevares for fremtidige generationer.      </p>
    </div>

    <div class="mt-12 bg-white shadow-xl rounded-lg py-5 px-6">
      <div class="text-center">
        <h3 class="text-2xl leading-8 font-extrabold tracking-tight sm:text-3xl text-gray-900">
Hvordan Virker Det?        </h3>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        OrgelStream tilbyder et omfattende bibliotek af orgelmusik, fra traditionelle salmer med præ/postludium til korsvar, alle indspillet og udført med stor musikalsk integritet samt respekt for genren af vores konservatorieuddannet personale. <br/><br/>Appen er designet til at være brugervenlig, hvilket gør det nemt for kirker at finde og afspille musikken, der passer bedst til deres specifikke behov. Med et par enkelte klik kan du skabe en stemningsfuld musikalsk ramme for enhver kirkelig ceremoni, uden at gå på kompromis med den musikalske oplevelse.
        </p>
      </div>
    </div>

    <div class="mt-10">
      <div class="space-y-12 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:space-y-0 lg:gap-y-12">
        <div class="bg-indigo-100 rounded-lg shadow px-6 py-8 ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="space-y-8">
            <div class="space-y-6">
              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h4>Carl Emil Tofte Jensen</h4>
                  <p class="text-indigo-600">Konservatorieuddannet Musiker</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-indigo-100 rounded-lg shadow px-6 py-8 ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="space-y-8">
            <div class="space-y-6">
              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h4>Philip John Pedersen</h4>
                  <p class="text-indigo-600">Software Ingeniør</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

)}

export default AboutUs;