import React from 'react';

const PlusIcon = ({ onClick }) => {
  return (
    <div 
      className="flex items-center justify-center w-12 h-12 sm:w-8 sm:h-8 group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 rounded-lg cursor-pointer hover:bg-blue-600" 
      onClick={onClick}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 24 24" 
        strokeWidth="1.5" 
        stroke="white" 
        className="w-12 h-12 sm:w-8 sm:h-8"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          d="M12 9v6m3-3H9m12 " 
        />
      </svg>
    </div>
  );
};

export default PlusIcon;
