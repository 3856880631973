import React from 'react';
import { Link } from 'react-router-dom';

const AboutUs = () => {return (
   <div class="bg-gray-50">


<div class="py-8 px-4 overflow-hidden sm:px-6 mx-auto lg:py-24 max-w-4xl">

   
  <h2 class="text-center text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl text-gray-900 pb-12">
Sådan bruger du OrgelStream     </h2>
<h2 class="text-center text-3xl leading-9 font-extrabold tracking-tight sm:text-2xl text-gray-900 pb-12">3 hurtige trin!</h2>
  
        <div class="text-center  mt-12 bg-violet-100 shadow-xl rounded-lg pt-8 pb-10 md:py-10 px-6 md:mx-0 mx-8">
              <div class="flex flex-col md:flex-row justify-between items-center md:items-start w-full">
          <div class="max-s-xs sm:max-w-xs pb-8 md:pb-0">
          <h1 class="text-left font-bold text-2xl pb-8">1. Opret Konto</h1>

            <p class="text-left text-xl  text-black">
              Det første du skal gøre, når du skal bruge OrgelStream, er at oprette en konto.
            </p>
          </div>
          <div class="aspect-w-16 aspect-h-9 "> 
            <iframe 
              class="w-full h-full rounded-lg" 
              src="https://www.youtube.com/embed/ClkeCOKqEqI?si=cOlWKkEwZBRBSIzj" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
              allowfullscreen>
            </iframe>
          </div>
        </div>


        </div>



        <div class="text-center  mt-12 bg-violet-100 shadow-xl rounded-lg pt-8 pb-10 md:py-10 px-6 md:mx-0 mx-8">
              <div class="flex flex-col md:flex-row justify-between items-center md:items-start w-full">
          <div class="max-s-xs sm:max-w-xs pb-8 md:pb-0">
          <h1 class="text-left font-bold text-2xl pb-8">2. Opret Playliste</h1>

            <p class="text-left text-xl  text-black">
            Herefter skal du oprette en playliste. Din playliste gemmes automatisk</p>
            
          </div>
          <div class="aspect-w-16 aspect-h-9 "> 
            <iframe 
              class="w-full h-full rounded-lg" 
              src="https://www.youtube.com/embed/ClkeCOKqEqI?si=cOlWKkEwZBRBSIzj" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
              allowfullscreen>
            </iframe>
          </div>
        </div>


        </div>


        <div class="text-center  mt-12 bg-violet-100 shadow-xl rounded-lg pt-8 pb-10 md:py-10 px-6 md:mx-0 mx-8">
              <div class="flex flex-col md:flex-row justify-between items-center md:items-start w-full">
          <div class="max-s-xs sm:max-w-xs pb-8 md:pb-0">
          <h1 class="text-left font-bold text-2xl pb-8">3. Tilføj til Playliste</h1>

            <p class="text-left text-xl  text-black ">
            Nu skal du tilføje ting til din playliste. Husk at alle dine ændringer gemmes automatisk!
            </p>
          </div>
          <div class="aspect-w-16 aspect-h-9 "> 
            <iframe 
              class="w-full h-full rounded-lg" 
              src="https://www.youtube.com/embed/ClkeCOKqEqI?si=cOlWKkEwZBRBSIzj" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
              allowfullscreen>
            </iframe>
          </div>
        </div>


        </div>
   


    
    <div class="text-center pb-8 mt-12 bg-violet-300 shadow-xl rounded-lg py-5 px-6 md:mx-0 mx-8">
      <h1 class=" font-bold text-2xl pb-6">Færdig!</h1>
      <div class=" flex flex-row justify-center w-full">
  
      <p class="text-center text-xl  text-black">
   Du er nu klar til at afspille din playliste fra "Mine Playlister" menuen!</p>
   
   
       </div>
    </div>


  

    
</div>

</div>


)}

export default AboutUs;