import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
 

<footer class="inset-x-0 bottom-0 w-full p-4 bg-gray-100 border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://www.orgelstream.dk/" class="hover:underline">OrgelStream</a>
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <Link to="/om-os" class="hover:underline me-4 md:me-6">Om os</Link>
        </li>
        <li>
            <Link to="/privatlivspolitik" class="hover:underline me-4 md:me-6">Privatlivspolitik</Link>
        </li>
        <li>
            <Link to="/kontakt" class="hover:underline me-4 md:me-6">Kontakt</Link>
        </li>
        
    </ul>
</footer>

  );
};

export default Footer;
