import { useState, useEffect } from 'react';
import { fetchUser } from '../services/userService';


export const useUser = (isLoggedIn) => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            handleFetchUser();
        }
    }, [isLoggedIn]);

    const handleFetchUser = async () => {
        try {
            const data = await fetchUser();
            setUser(data.user);
        } catch (error) {
        }

    };

    return user;

};