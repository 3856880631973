// PlaylistModal.js

import React from 'react';

const PlaylistModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full md:w-full " onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-72 lg:w-80 shadow-lg rounded-md bg-white mt-40" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default PlaylistModal;
