import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPlaylistDetails, addSongToPlaylist, removeSongFromPlaylist, searchSongs, updatePlaylist } from '../services/playlistService'; // Make sure this function is exported from your service
import { fetchStreamUrlForSong } from '../services/songService'; // Adjust the import path as necessary
import SongItem from './SongItem';
import Loading from './Loading';
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css'; // Default styles
import '../audioPlayerOverridden.css';






const PlaylistView = () => {
  const { playlistId } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [songId, setSongId] = useState(''); // State to hold the song ID to be added
  const [songSearch, setSongSearch] = useState(''); // Input value for song search
  const [songSuggestions, setSongSuggestions] = useState([]); // Suggestions based on search
  const [currentSongUrl, setCurrentSongUrl] = useState('');
  const [currentSongName, setCurrentSongName] = useState('');
  const [currentSongAuthor, setCurrentSongAuthor] = useState('');
  const [currentSongId, setCurrentSongId] = useState('');
  const [salmeActive, setSalmeActive] = useState(false);
  const [korsvarActive, setKorsvarActive] = useState(false);
  const [præludiumActive, setPræludiumActive] = useState(false);
  const [postludiumActive, setPostludiumActive] = useState(false);
  const audioPlayerRef = useRef(null);
  const [categorizedSongs, setCategorizedSongs] = useState({
    salme: [],
    korsvar: [],
    postludium: [],
    præludium: [],
  });
  const [categoryView, setCategoryView] = useState(false);
  const [unCategorizedSongs, setUncategorizedSongs] = useState(null);
  const [currentSongUrlNew, setCurrentSongUrlNew] = useState(''); // New state to hold the song URL

   
  // useEffect hook to update currentSongUrl whenever currentSongName changes
 /*
  useEffect(() => {
    const getSongUrl = async () => {
        try {
            const titleWithPrefixAndExtension = currentSongUrl.slice(42);
            console.log(titleWithPrefixAndExtension)
            const streamingUrl = await fetchStreamUrlForSong(titleWithPrefixAndExtension);
            console.log(streamingUrl)
            setCurrentSongUrlNew(streamingUrl);
        } catch (error) {
            console.error(error);
            // Handle the error appropriately
        }
    };

    if (currentSongName) {
        getSongUrl();
    }
}, [currentSongName]);
*/


  useEffect(() => {


    

    const loadPlaylistDetails = async () => {
      try {
        const playlistDetails = await fetchPlaylistDetails(playlistId);
        setPlaylist(playlistDetails.playlist);

         // Initialize an empty structure for categorized songs
      const newCategorizedSongs = {
        salme: [],
        korsvar: [],
        postludium: [],
        præludium: [],
        // ... other categories ...
      };

      // Categorize songs
      playlistDetails.playlist.songs.forEach(song => {
        if (newCategorizedSongs.hasOwnProperty(song.type)) {
          newCategorizedSongs[song.type].push(song);
        }
      });

      // Update the state with the categorized songs
      setCategorizedSongs(newCategorizedSongs);
      setPræludiumActive(newCategorizedSongs.præludium.length > 0);
      setKorsvarActive(newCategorizedSongs.korsvar.length > 0);
      setSalmeActive(newCategorizedSongs.salme.length > 0);
      setPostludiumActive(newCategorizedSongs.postludium.length > 0);

      
      } catch (err) {
        setError(err.message || 'An error occurred while fetching the playlist details');
      } finally {
        setLoading(false);
      }

      
    };

    loadPlaylistDetails();
  }, [playlistId]);

  const loadPlaylistDetails = async () => {
    try {
      setLoading(true);
      setError('');
      const playlistDetails = await fetchPlaylistDetails(playlistId);
      setPlaylist(playlistDetails); // Update your state with the fetched details

      // Additional logic to handle the fetched data (e.g., categorizing songs)
    } catch (err) {
      setError(err.message || 'An error occurred while fetching the playlist details');
    } finally {
      setLoading(false);
    }
  };

  const handleSongSearch = async (searchTerm) => {
    setSongSearch(searchTerm);
    if (searchTerm.length > 2) {
        const suggestions = await searchSongs(searchTerm);
        setSongSuggestions(suggestions);
    } else {
        setSongSuggestions([]);
    }
};
const selectSong = async (song) => {
  setSongId(song._id); // Set the selected song's ID
  setSongSearch(''); // Reset the search field

  try {
    await addSongToPlaylist(playlistId, song._id);
    const playlistDetails = await fetchPlaylistDetails(playlistId);
    setPlaylist(playlistDetails.playlist);

    // Categorize songs again with the updated playlist
    const newCategorizedSongs = {
      salme: [],
      korsvar: [],
      postludium: [],
      præludium: [],
      // ... other categories ...
    };

    playlistDetails.playlist.songs.forEach(song => {
      if (newCategorizedSongs.hasOwnProperty(song.type)) {
        newCategorizedSongs[song.type].push(song);
      }
    });

    // Update the categorizedSongs state
    setCategorizedSongs(newCategorizedSongs);

    // Update the individual type active states
    setPræludiumActive(newCategorizedSongs.præludium.length > 0);
    setKorsvarActive(newCategorizedSongs.korsvar.length > 0);
    setSalmeActive(newCategorizedSongs.salme.length > 0);
    setPostludiumActive(newCategorizedSongs.postludium.length > 0);

  } catch (err) {
    console.error('error adding to playlist:', err);
  }
  setSongSuggestions([]); // Clear suggestions
};


const handleRemoveSong = async (songId) => {
  try {
    await removeSongFromPlaylist(playlistId, songId);
    const playlistDetails = await fetchPlaylistDetails(playlistId);
    setPlaylist(playlistDetails.playlist);

  } catch (err) {
    console.error('error removing from playlist:', err);
  }
}

  const handleSetCategory = () => {
    console.log(categoryView)

    console.log("Toggling categoryView");
    setCategoryView(prevCategoryView => !prevCategoryView);
    console.log(categoryView)

  }
  
  const setPlaylistContent = (newSongs) => {
    // Update the local state
    setPlaylist((prevPlaylist) => ({
      ...prevPlaylist,
      songs: newSongs
    }));
  
    // Call a service function to update the server
    updatePlaylistOnServer(playlistId, newSongs);
  };

  const onDragStart = (event, songId) => {
    event.dataTransfer.setData("text/plain", songId);
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Necessary to allow dropping
  };

  const updatePlaylistOnServer = async (playlistId, newSongs) => {
    // Transform the song objects to a format the server expects, if necessary
    // For example, the server might expect an array of song IDs
    const songIds = newSongs.map(song => song._id);
  
    try {
      // Assuming updatePlaylist is a function that makes an HTTP request to update the playlist
      // You would need to implement this function to match your server's API
      await updatePlaylist(playlistId, songIds);
      console.log('Playlist updated on server');
    } catch (error) {
      console.error('Failed to update playlist on server', error);
    }
  };

  const onDrop = (event, droppedOnSongId) => {
    event.preventDefault();
    const draggedSongId = event.dataTransfer.getData("text/plain");
    const draggedSongIndex = playlist.songs.findIndex(song => song._id === draggedSongId);
    const droppedOnSongIndex = playlist.songs.findIndex(song => song._id === droppedOnSongId);

    const newSongs = [...playlist.songs];
    const [reorderedSong] = newSongs.splice(draggedSongIndex, 1);
    newSongs.splice(droppedOnSongIndex, 0, reorderedSong);

    setPlaylist({
      ...playlist,
      songs: newSongs
    });

    setPlaylistContent(newSongs);
    updatePlaylistOnServer(playlistId, newSongs);

  };



  if (loading) return <Loading/>
  if (error) return <div>Error: {error}</div>;
  if (!playlist) return <div>No playlist found {playlistId}</div>;




  return (
    <div className="bg-indigo-0 text-purple-900 min-h-screen pt-8 pb-36">
      <div className="px-8 lg:px-12">
      <h2 className="text-2xl font-bold mb-4">Playliste: {playlist.name}</h2>
  
      <div className="mb-4 flex flex-row justify-between">

        <div>
        <p className="mb-2">Tilføj musik ved at søge i feltet:</p>

        <input 
            type="text" 
            value={songSearch} 
            onChange={(e) => handleSongSearch(e.target.value)} 
            placeholder="Indtast sang titel" 
            className="px-3 py-2 border border-purple-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
        />
        </div>
        <div>

        <label class="inline-flex items-center cursor-pointer" >
          <input type="checkbox" value="" class="sr-only peer" onClick={handleSetCategory}/>
          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
        </div>
      </div>
  
      <div className="mb-4 ">
        {songSuggestions.map(song => (
            <div key={song._id} onClick={() => selectSong(song)} className="p-2 hover:bg-purple-200 rounded cursor-pointer">
                {song.title} - {song.artist}
            </div>
        ))}
      </div>
     
      
  
      { !categoryView &&  
      
      <div onDragOver={onDragOver}>
      
      
    {playlist?.songs?.length > 0 ? (
      playlist.songs.map((song, index) => (
        <div draggable="true" key={song._id} 
          className={`draggable-song-item mb-2 p-2 px-4 border-b border-purple-200 cursor-pointer bg-white ${currentSongId === song._id ? 'bg-purple-200 shadow-sm rounded-md' : 'sm:hover:bg-purple-200 shadow-sm rounded-md'}`}
          onDragStart={(event) => onDragStart(event, song._id)}
          onDrop={(event) => onDrop(event, song._id)}
          onClick={() => {
           console.log("Song URL:", song.url);
           setCurrentSongUrl(song.url);
           setCurrentSongName(song.title);
           setCurrentSongAuthor(song.artist);
           setCurrentSongId(song._id); // Set this song as the active song

         }}
         >
          <div class="flex flex-row items-center">
          <div class="pr-4">
                    <h1 class="text-lg">{index+1}</h1>
                    </div>
                    <div class="flex flex-row items-center justify-between w-full">
                    <div>
          <p className="text-lg">{song.type.slice(0,1).toUpperCase()}{song.type.slice(1)}: {song.title} {song.type=="salme" && `(${song.number})`}</p>
          <p className="text-sm text-purple-700">Kunstner: {song.artist}</p>
          
          </div>
          <div class="pr-4"> <button onClick={(e) => {
            console.log('Button clicked'); // Check if this logs when the button is clicked
            e.preventDefault(); // Prevent the default action.
            
            e.stopPropagation();
            handleRemoveSong(song._id);
            
          }}>
            
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 sm:hover:fill-red-500 stroke-red-500 ">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

          </button></div>
          </div>
        </div>
        </div>
      ))
    ) : (
      <p>Ingen sange i denne playliste.</p>
    )}
  </div>}

      { categoryView &&  <div> 
      
      {præludiumActive &&  <div className="præludium-div">
        {/* Render Salme Songs */}
        <h1 class="font-bold">Præludium:</h1>
        {categorizedSongs.præludium.map((song, index) => (
          
          <div key={song._id} /* ... */>
            {<div key={song._id} 
          className={`mb-2 p-2 px-4 border-b border-purple-200 cursor-pointer bg-white ${currentSongId === song._id ? 'bg-purple-200 shadow-sm rounded-md' : 'hover:bg-purple-200 shadow-sm rounded-md'}`}
          onClick={() => {
           console.log("Song URL:", song.url);
           setCurrentSongUrl(song.url);
           setCurrentSongName(song.title);
           setCurrentSongAuthor(song.artist);
           setCurrentSongId(song._id); // Set this song as the active song

         }}
         >
          <div class="flex flex-row items-center">
          <div class="pr-4">
                    <h1 class="text-lg">{index+1}</h1>
                    </div>
                    <div class="flex flex-row items-center justify-between w-full">
            <div> 
       <p className="text-lg">Præludium: {song.title}</p>
       <p className="text-sm text-purple-700">Kunstner: {song.artist}</p>
          </div>
          <div class="pr-4"> <button onClick={(e) => {
            console.log('Button clicked'); // Check if this logs when the button is clicked
            e.preventDefault(); // Prevent the default action.
            
            e.stopPropagation();
            handleRemoveSong(song._id);
            
          }}>
            
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 sm:hover:fill-red-500 stroke-red-500 ">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

          </button></div>
         </div>
       </div>
     </div>}
          </div>
        ))}
      </div>}


      {korsvarActive && 
      <div className="korsvar-div">
        {/* Render Salme Songs */}
        <h1 class="font-bold">Korsvar:</h1>
        {categorizedSongs.korsvar.map((song, index) => (
          <div key={song._id} /* ... */>
            {<div key={song._id} 
          className={`mb-2 p-2 px-4 border-b border-purple-200 cursor-pointer bg-white ${currentSongId === song._id ? 'bg-purple-200 shadow-sm rounded-md' : 'hover:bg-purple-200 shadow-sm rounded-md'}`}
          onClick={() => {
           console.log("Song URL:", song.url);
           setCurrentSongUrl(song.url);
           setCurrentSongName(song.title);
           setCurrentSongAuthor(song.artist);
           setCurrentSongId(song._id); // Set this song as the active song
         



         }}

         ><div class="flex flex-row items-center">
         <div class="pr-4">
                   <h1 class="text-lg">{index+1}</h1>
                   </div>
                   <div class="flex flex-row items-center justify-between w-full">
           <div> 
      <p className="text-lg">Korsvar: {song.title}</p>
      <p className="text-sm text-purple-700">Kunstner: {song.artist}</p>
         </div>
         <div class="pr-4"> <button onClick={(e) => {
           console.log('Button clicked'); // Check if this logs when the button is clicked
           e.preventDefault(); // Prevent the default action.
           
           e.stopPropagation();
           handleRemoveSong(song._id);
           
         }}>
           
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 sm:hover:fill-red-500 stroke-red-500 ">
 <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

         </button></div>
        </div>
      </div>
     </div>}
          </div>
        ))}
      </div>
}
      {salmeActive && 
      <div className="salme-div">
        {/* Render Salme Songs */}
        <h1 class="font-bold">Salmer:</h1>
        {categorizedSongs.salme.map((song, index)=> (
          <div key={song._id} /* ... */>
            {<div key={song._id} 
          className={`mb-2 p-2 px-4 border-b border-purple-200 cursor-pointer bg-white ${currentSongId === song._id ? 'bg-purple-200 shadow-sm rounded-md' : 'hover:bg-purple-200 shadow-sm rounded-md'}`}
          onClick={() => {
           console.log("Song URL:", song.url);
           setCurrentSongUrl(song.url);
           setCurrentSongName(song.title);
           setCurrentSongAuthor(song.artist);
           setCurrentSongId(song._id); // Set this song as the active song
          


         }}
         ><div class="flex flex-row items-center">
         <div class="pr-4">
                   <h1 class="text-lg">{index+1}</h1>
                   </div>
                   <div class="flex flex-row items-center justify-between w-full">
           <div> 
      <p className="text-lg">Salme: {song.title}</p>
      <p className="text-sm text-purple-700">Kunstner: {song.artist}</p>
         </div>
         <div class="pr-4"> <button onClick={(e) => {
           console.log('Button clicked'); // Check if this logs when the button is clicked
           e.preventDefault(); // Prevent the default action.
           
           e.stopPropagation();
           handleRemoveSong(song._id);
           
         }}>
           
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 sm:hover:fill-red-500 stroke-red-500 ">
 <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

         </button></div>
        </div>
      </div>
     </div>}
          </div>
        ))}
      </div>
}
      {postludiumActive &&    <div className="postludium-div">
        {/* Render Postludium Songs */}
        <h1 class="font-bold">Postludium:</h1>

        {categorizedSongs.postludium.map((song, index) => (
          <div key={song._id} /* ... */>
            { <div key={song._id} 
          className={`mb-2 p-2 px-4 border-b border-purple-200 cursor-pointer bg-white ${currentSongId === song._id ? 'bg-purple-200 shadow-sm rounded-md' : 'hover:bg-purple-200 shadow-sm rounded-md '}`}
          onClick={() => {
           console.log("Song URL:", song.url);
           setCurrentSongUrl(song.url);
           setCurrentSongName(song.title);
           setCurrentSongAuthor(song.artist);
           setCurrentSongId(song._id); // Set this song as the active song


         }}
         ><div class="flex flex-row items-center">
         <div class="pr-4">
                   <h1 class="text-lg">{index+1}</h1>
                   </div>
                   <div class="flex flex-row items-center justify-between w-full">
           <div> 
      <p className="text-lg">Postludium: {song.title}</p>
      <p className="text-sm text-purple-700">Kunstner: {song.artist}</p>
         </div>
         <div class="pr-4"> <button onClick={(e) => {
           console.log('Button clicked'); // Check if this logs when the button is clicked
           e.preventDefault(); // Prevent the default action.
           
           e.stopPropagation();
           handleRemoveSong(song._id);
           
         }}>
           
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 sm:hover:fill-red-500 stroke-red-500 ">
 <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

         </button></div>
        </div>
      </div>
     </div>
     }
          </div>
        ))}
      </div>}

    

   

  </div>}


  </div>
  {currentSongUrl && (
    <div>
            <div class="md:hidden">
          
{/*<audio controls>
  <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" type="audio/mpeg"/>
  Your browser does not support the audio element.
  </audio> USE THIS FOR DEBUGGING AUDIO*/} 

        
            </div>
        
            <div class="!bg-gradient-to-r from-purple-600 to-blue-600 flex flex-row mt-4 w-full fixed bottom-0 z-10 pb-2 md:pb-0">
              <div class="hidden md:block md:min-w-40 lg:min-w-80">
              
                <h1 class="md:text-center text-white font-bold">{currentSongName}</h1>
                <h1 class="md:text-center text-stone-400">{currentSongAuthor}</h1>
              </div>
          <ReactH5AudioPlayer
            src={currentSongUrl}
            showSkipControls={true}
            header={true}
            autoPlay={false}
            controls
            layout="stacked-reverse"
            className="!bg-transparent !shadow-none"
            // Additional props and event handlers
      /></div>
  </div>
)}
    </div>
  );
  

};

export default PlaylistView;
