const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const fetchSongFromPlaylist = async() => {
    try {
        const response = await fetch(`${API_BASE_URL}/playlists/:playlistId`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching playlists');
        return await response.json();
    } catch (error) {
        console.error('fetchSongFromPlaylist error:', error);
        throw error;
    }
};


export const fetchAllKorsvar = async () => {

    try {
        const response = await fetch(`${API_BASE_URL}/songs/korsvar`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching korsvar');
                console.log(response); 

        return await response.json();
    }
    catch (error) {
        console.error('fetchAllKorsvar error:', error);
        throw error;
    }
};

export const fetchAllSalmer = async () => {

    try {
        const response = await fetch(`${API_BASE_URL}/songs/salmer`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching salmer');
                console.log(response); 

        return await response.json();
    }
    catch (error) {
        console.error('fetchAllSalmer error:', error);
        throw error;
    }
};

export const fetchAllPreludium = async () => {

    try {
        const response = await fetch(`${API_BASE_URL}/songs/preludium`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching preludium');
                console.log(response); 

        return await response.json();
    }
    catch (error) {
        console.error('fetchAllPreludium error:', error);
        throw error;
    }
};

export const fetchAllPostludium = async () => {

    try {
        const response = await fetch(`${API_BASE_URL}/songs/postludium`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching postludium');
                console.log(response); 

        return await response.json();
    }
    catch (error) {
        console.error('fetchAllPostludium error:', error);
        throw error;
    }
};

/*
export const fetchStreamUrlForSong = async (songName) => {
    // Directly construct and return the URL for the song stream endpoint
    // Assuming the endpoint expects a song name or ID
    const url = `${API_BASE_URL}/songs/stream/${encodeURIComponent(songName)}`;
    return url;
};
*/
