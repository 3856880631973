import React, { useState } from 'react';

const pricing = [
  { churches: 1, price: 1999 },
  { churches: 2, price: 3799 },
  { churches: 3, price: 5499 },
  { churches: 4, price: 6999 }
];

const getPrice = (churches) => {
  const plan = pricing.find(plan => plan.churches === churches);
  return plan ? plan.price : pricing[pricing.length - 1].price;
};

const PricingSlider = () => {
  const [churches, setChurches] = useState(1);
  const [price, setPrice] = useState(getPrice(1));

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setChurches(value);
    setPrice(getPrice(value));
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-10">
      <div className="bg-white shadow-md rounded-lg p-6 w-11/12 md:w-1/2 lg:w-1/3 text-center">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4">Church Subscription Pricing</h1>
        <p className="text-lg text-gray-700 mb-4">Vælg dét antal kirker som OrgelStream skal bruges I:</p>
        <input 
          type="range" 
          min="1" 
          max="4" 
          value={churches} 
          onChange={handleSliderChange} 
          className="w-full mb-4"
        />
        <div className="flex justify-between mb-4">
          <span>1 Church</span>
          <span>2 Churches</span>
          <span>3 Churches</span>
          <span>4 Churches</span>
        </div>
        <div className="bg-gray-200 p-4 rounded">
          <p className="text-lg text-gray-700">Antal Kirker: <span className="font-bold">{churches}</span></p>
          <p className="text-lg text-gray-700">Månedlig Pris: <span className="font-bold">{price} ,-</span></p>
        </div>
      </div>
    </div>
  );
};

export default PricingSlider;
