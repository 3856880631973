import React from 'react';
import Loading from './Loading';
import PricingSlider from './PricingSlider';
import PricingTiers from './PricingTiers';
import { Link } from 'react-router-dom';

const Profile = ({ user }) => {
    if (!user) {
        return (<Loading />); // Show loading message while fetching user data
    }

    return (
      <div>
        <div>
        </div>
        <div className="flex flex-col items-center bg-gray-100 py-10">
            <div className="bg-white shadow-md rounded-lg p-6 w-11/12 md:w-1/2 lg:w-1/3">
                <div className="flex flex-col items-center">
                    
                    <h1 className="text-2xl font-bold text-gray-800">Min Profil</h1>
                </div>
                <div className="mt-6">
                    <h2 className="text-lg font-medium text-gray-700">Konto Oplysninger</h2>
                    <ul className="text-gray-600 mt-2"><li><strong>Email:</strong> {user.email}</li></ul>
                </div>
                <div className="mt-6">
                    <h2 className="text-lg font-medium text-gray-700">Adgang</h2>
                    <ul className="text-gray-600 mt-2">
                        <li><strong>Abonnement:</strong> {user.subname}</li>
                        {user.substatus === 'active' ? <li><strong>Status:</strong> Aktiv</li>: <li><strong>Status:</strong> Afmeldt d.{user.canceldate} med udløb d.{user.expirydate}</li> }
                    </ul>
                </div>
                <div className="mt-4">
                    <Link to="/abonnementer">
                <button type="button" class="w-auto relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-white rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 px-4 py-2">Opdater Abonnement</button>
                </Link>
                </div>
            </div>
           

        </div>

        
        </div>
    );
};

export default Profile;
