import React, { useEffect, useState } from 'react'; // Import useState and useEffect
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import PlaylistDetail from './PlaylistDetail';
import { useAuth } from './hooks/useAuth';
import { usePlaylist } from './hooks/usePlaylist';
import { useSong } from './hooks/useSong';
import { useUser } from './hooks/useUser';

import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import Header from './components/Header';
import PlaylistManager from './components/PlaylistManager'; // Import PlaylistManager
import PlaylistView from './components/PlaylistView'; // Updated import
import AboutUs from './components/AboutUs'; // 
import How from './components/How'; //
import Services from './components/Services'; //
import Korsvar from './components/Korsvar'; //
import Salmer from './components/Salmer';
import Postludium from './components/Postludium';
import Preludium from './components/Preludium';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import Profile from './components/Profile';
import Loading from './components/Loading';
import PricingTiers from './components/PricingTiers';


import ProtectedRoute from './components/ProtectedRoute';
import GuestOnlyRoute from './components/GuestOnlyRoute';
import { LargeInput, DefaultInput, SmallInput } from './ui/TextField';
import mockupImage from './img/kirkeorglet3.png'; // Adjust the path and filename as necessary





function App() {
  const { isLoggedIn, isLoading, loginMessage, handleLogin, handleLogout, handleRegister } = useAuth();
  const { playlists, handleCreatePlaylist, handleAddSongToPlaylist, handleDeletePlaylist } = usePlaylist(isLoggedIn);
  const { songs, postludium, preludium, korsvar, salmer, handleGetAllKorsvar, handleGetAllPostludium, handleGetAllPreludium, handleGetAllSalmer } = useSong(isLoggedIn);
  const  user  = useUser(isLoggedIn);
  
  if (isLoading) {
    return <Loading/>
  }

  return (
    <Router>
      <div className="App pt-16 min-h-screen flex flex-col bg-gray-100">
        <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <div className=" flex-1">
        <Routes>
          <Route path="/" element={
            <GuestOnlyRoute isLoggedIn={isLoggedIn} redirectTo={"/my-playlists"}> 
            <div className="p-8 lg:pt-24 2xl:pt-32 py-12 md:px-32 lg:pr-26 2xl:pl-80 flex flex-col md:flex-row"> {/* Changed to flex-row on md screens */}
    <div className="flex-1 flex flex-col justify-center items-start"> {/* First column */}
        <div className="text-2xl md:text-4xl font-medium md:mb-8 mb-4">
            <h1> Ingen organist til kirken? <br></br> Intet problem! </h1>
        </div>
        <div className="text-xl font-medium">
            <p>Hos OrgelStream sørger vi for, at I kan gennemføre jeres gudstjeneste med orgelmusik til præludium, korsvar, salmer og postludium, selvom I står og mangler en organist!</p>
        </div>
        <div className="mt-6">
          <Link to="/login">
                <button class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
        <span class="relative px-4 py-1.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
        Log På
        </span>
        </button></Link>
          <Link to="/register">
        <button type="button" class="md:ml-2 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-white rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 px-4 py-2">Opret Bruger</button>
        </Link>
        </div>
    </div>
    <div className="flex-1 flex justify-end items-center pt-16 md:pt-0"> {/* Second column */}
    <img src={mockupImage} style={{ width: '700px', height: 'auto' }} alt="Description of Image" /> {/* Use the imported image here */}
</div>

</div>

              </GuestOnlyRoute>         
             }/>
              <Route path="/om-os" element={
                <AboutUs />
             }/>
             <Route path="/services" element={
                <Services />
             }/>
             <Route path="/hvordan" element={
                <How />
             }/>
             <Route path="/privatlivspolitik" element={
                <PrivacyPolicy />
             }/>
             <Route path="/konto" element={
              <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/login"}>
                <Profile user={user}/>
              </ProtectedRoute>
             }/>
             <Route path="/login" element={
            <GuestOnlyRoute isLoggedIn={isLoggedIn} redirectTo={"/my-playlists"}> 
                <LoginForm onLogin={handleLogin} message={loginMessage} />
              </GuestOnlyRoute>         
             }/>
             <Route path="/register" element={
            <GuestOnlyRoute isLoggedIn={isLoggedIn} redirectTo={"/my-playlists"}> 
                <RegisterForm onRegister={handleRegister} />
              </GuestOnlyRoute>         
             }/>
          <Route path="/my-playlists" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/"}>
              <PlaylistManager
                playlists={playlists}
                onCreatePlaylist={handleCreatePlaylist}
                onAddSongToPlaylist={handleAddSongToPlaylist}
                onDeletePlaylist={handleDeletePlaylist}
              />
            </ProtectedRoute>
          } />
          <Route path="/abonnementer" element={
              <PricingTiers/>
          } />
          {/* Playlist detail route */
          }
            <Route path="/playlist/:playlistId" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/"}>
              <PlaylistView/>
            </ProtectedRoute>
          } />
          <Route path="/postludium" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/"}>
              <Postludium songs={postludium} playlists={playlists} onGetAllPostludium={handleGetAllPostludium}/>
            </ProtectedRoute>
          } />
          <Route path="/preludium" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/"}>
              <Preludium songs={preludium} playlists={playlists} onGetAllPreludium={handleGetAllPreludium}/>
            </ProtectedRoute>
          } />
          <Route path="/korsvar" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/"}>
              <Korsvar songs={korsvar} playlists={playlists} onGetAllKorsvar={handleGetAllKorsvar}/>
            </ProtectedRoute>
          } />
          <Route path="/salmer" element={
            <ProtectedRoute isLoggedIn={isLoggedIn} redirectTo={"/"}>
              <Salmer songs={salmer} playlists={playlists} onGetAllSalmer={handleGetAllSalmer} />
            </ProtectedRoute>
          } />

          {/* Other routes */}
        </Routes>
        </div>
        <Footer/>
        
      </div>
    </Router>
  );
}

export default App;
