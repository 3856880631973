import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DefaultInput } from '../ui/TextField';
import MyButton from '../ui/Button';

function RegisterForm({ onRegister, message }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onRegister(email, password);
  };

  return (
    <div class="max-w-xs mx-auto text-center pt-16">
      
      <h1 class="font-extrabold text-center text-4xl tracking-tight mb-5">Opret Profil</h1>
      <p class="mb-10">Har du allerede en profil? <Link className="font-bold text-purple-800" to="/login">Log på</Link></p>

      <form onSubmit={handleSubmit}>
       <DefaultInput
          type="text"
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <DefaultInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
       <div class="max-w-xl"> <MyButton type="submit">Opret Profil</MyButton>
        {message && <p>{message}</p>} {/* Display the message here */}
        </div>
      </form>
    </div>
  );
}

export default RegisterForm;
