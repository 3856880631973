import React from 'react';

const ServicesComponent = () => {
    return (
        <div className="pt-24 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Vi Tilbyder
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
Gå på opdagelse i de egenskaber der gør OrgelStream unik                    </p>
                </div>

                <div className="pt-24">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-8">
                        {/* Live Organ Streaming Feature */}
                        <div className="relative">
                            <dt>
                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                                
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="fill-white"viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"/></svg>
                                </div>
                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Professionelt indspillet Orgelmusik</p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                Alle vores musikstykker er indspillet af konservatorieuddannet personale, og er af højeste kvalitet.
                            </dd>
                        </div>

                        {/* On-Demand Video Library Feature */}
                        <div className="relative">
                            
                            <dt>
                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                                                        
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="fill-white"viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"/></svg>
                 
                                    {/* Icon placeholder */}
                                </div>
                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Salmer og meget mere</p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                Få adgang til et omfattende bibliotek af orgelmusik, fra traditionelle salmer med præ/postludium til korsvar.
                            </dd>
                        </div>

                        {/* Interactive Song Requests Feature */}
                        <div className="relative">
                            <dt>
                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                                                            
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="fill-white"viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"/></svg>
                  {/* Icon placeholder */}
                                </div>
                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Dine egne Playlister</p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                Skab dine egne playlister, og afspil dem direkte til gudstjeneste, bryllup, begravelse eller andre kirkelige begivenheder.
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
}

export default ServicesComponent;
