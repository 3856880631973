import { useState, useEffect } from 'react';
import { fetchPlaylists, createPlaylist, addSongToPlaylist, deletePlaylist } from '../services/playlistService';

export const usePlaylist = (isLoggedIn) => {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserPlaylists();
    }
  }, [isLoggedIn]);

  const fetchUserPlaylists = async () => {
    try {
      const data = await fetchPlaylists();
      setPlaylists(data.playlists);
    } catch (error) {
      console.error('Error fetching playlists:', error);
    }
  };

// FUNCTIONS AFTER THIS COMMENT ARE CONSUMED BY THE USEPLAYLIST HOOK PASSED TO PLAYLISTMANAGER THROUGH APP.JS

  const handleDeletePlaylist = async (playlistId) => {
    try {
      await deletePlaylist (playlistId);
      fetchUserPlaylists();
    } catch (error) {
      console.error('Error deleting playlist', error);
    }
  }

  const handleCreatePlaylist = async (playlistName) => {
    try {
      await createPlaylist(playlistName);
      fetchUserPlaylists();
    } catch (error) {
      console.error('Error creating playlist:', error);
    }
  };

  const handleAddSongToPlaylist = async (playlistId, songId) => {
    try {
      await addSongToPlaylist(playlistId, songId);
      fetchUserPlaylists();
    } catch (error) {
      console.error('Error adding song:', error);
    }
  };

  return { playlists, handleCreatePlaylist, handleAddSongToPlaylist, handleDeletePlaylist };
};
