import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, isLoggedIn, redirectTo}) => {
  if (!isLoggedIn) {
    // Redirect to the home page if not logged in
    return <Navigate to={redirectTo} />;
  }

  // Render the children components if logged in
  return children;
};

export default ProtectedRoute;
