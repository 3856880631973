import React, { useState } from 'react';
import { searchSongs, addSongToPlaylist} from '../services/playlistService';
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Link } from 'react-router-dom';

const Salmer = ({songs, playlists, handleGetAllSalmer}) => { 

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentSongUrl, setCurrentSongUrl] = useState('');
    const [currentSongName, setCurrentSongName] = useState('');
    const [currentSongAuthor, setCurrentSongAuthor] = useState('');
    const [currentSongId, setCurrentSongId] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [selectedSongId, setSelectedSongId] = useState(null);
    const [showPlaylistDropdown, setShowPlaylistDropdown] = useState({});





   


        
    const handleSearch = async (searchTerm) => {
      setSearchTerm(searchTerm);
      setIsSearchActive(searchTerm.length > 0);

      
      // Check if the search term is numerical (potentially a psalm number)
      if (!isNaN(searchTerm)) {
          // If numerical, search even if it's just one digit
          if (searchTerm.length >= 1) {
              const results = await searchSongs(searchTerm);
              setSearchResults(results);
          } else {
              setSearchResults([]);
          }
      } else {
          // If not numerical, wait for at least 3 characters before searching
          if (searchTerm.length >= 3) {
              const results = await searchSongs(searchTerm);
              setSearchResults(results);
          } else {
              setSearchResults([]);
          }
      }
  };
  

    const handleAddToPlaylist = async (e, playlistId, salmeId) => {
        e.stopPropagation(); // Stop the click from propagating
        try {
        await addSongToPlaylist(playlistId, salmeId);
        alert('Salme tilføjet til playliste');
        // Optionally refresh the playlist or provide feedback to the user
        } catch (error) {
        console.error('Error adding salme to playlist:', error);
        alert('Failed to add salme to playlist');
        }
        setShowPlaylistDropdown({});

    };

    const toggleSelectSongAndPlaylistDropdown = (songIdProp) => {
        setSelectedSongId(songIdProp);
    }

   
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest('button')
      ) {
        setShowPlaylistDropdown({});
        setSelectedSongId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const togglePlaylistDropdown = (e, songId) => {
    e.preventDefault();
    e.stopPropagation();
    // Toggle the state for this song ID only
    setShowPlaylistDropdown(prevState => {
        // Create a new state object with all dropdowns closed
        const newState = Object.keys(prevState).reduce((state, id) => {
            state[id] = false; // Set all to false
            return state;
        }, {});
        
        // Toggle the state for the current song ID
        newState[songId] = !prevState[songId];
        return newState;
    });
    console.log("playlist dropdown toggled for songId: ", songId);
};


    const listToRender = isSearchActive ? searchResults : songs;
    const dropdownRef = React.useRef(null);




    return (
        <div class="pt-8 pb-36">
            <div class="px-8 lg:px-12 text-purple-900">
        <h1 className="text-2xl font-bold mb-4 text-purple-900">Salmer</h1>
<div class="mb-4">
           <p class="mb-2">Søg efter salmer:</p>
<input
  type="text"
  value={searchTerm}
  onChange={(e) => handleSearch(e.target.value)}
  placeholder="Indtast salme nr. eller titel"
  class="px-3 py-1 border border-purple-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
/>
</div>



{listToRender.map((song) => (
    <div key={song.id}           
    className={`flex flex-row justify-between draggable-song-item mb-2 p-2 px-4 border-b border-purple-200 items-center cursor-pointer bg-white ${currentSongId === song._id ? 'bg-purple-200 shadow-sm rounded-md' : 'sm:hover:bg-purple-200 shadow-sm rounded-md'}`}
    onClick={() => {
        console.log("Song URL:", song.url);
        setCurrentSongUrl(song.url);
        setCurrentSongName(song.title);
        setCurrentSongAuthor(song.artist);
        setCurrentSongId(song._id); // Set this song as the active song
       

      }}
    > {/* Use a unique identifier as key */}
    <div>
        <h2 class="align-middle inline-block">{song.number}. {song.title}</h2>
        
        </div>
        <div class=" relative inline-block pr-4 pl-4 "> <button onClick={(e) => {
            toggleSelectSongAndPlaylistDropdown(song._id);
            togglePlaylistDropdown(e, song._id);
                    }} class=" align-middle inline-block">
            
         {/* REPLACE WITH ADD TO PLAYLIST BUTTON <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 hover:fill-red-500 stroke-red-500 ">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>*/}

<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 128 128" class="fill-violet-600 sm:hover:fill-violet-900 md:stroke-purple-600">
    <path d="M 64 6.0507812 C 49.15 6.0507812 34.3 11.7 23 23 C 0.4 45.6 0.4 82.4 23 105 C 34.3 116.3 49.2 122 64 122 C 78.8 122 93.7 116.3 105 105 C 127.6 82.4 127.6 45.6 105 23 C 93.7 11.7 78.85 6.0507812 64 6.0507812 z M 64 12 C 77.3 12 90.600781 17.099219 100.80078 27.199219 C 121.00078 47.499219 121.00078 80.500781 100.80078 100.80078 C 80.500781 121.10078 47.500781 121.10078 27.300781 100.80078 C 7.0007813 80.500781 6.9992188 47.499219 27.199219 27.199219 C 37.399219 17.099219 50.7 12 64 12 z M 64 42 C 62.3 42 61 43.3 61 45 L 61 61 L 45 61 C 43.3 61 42 62.3 42 64 C 42 65.7 43.3 67 45 67 L 61 67 L 61 83 C 61 84.7 62.3 86 64 86 C 65.7 86 67 84.7 67 83 L 67 67 L 83 67 C 84.7 67 86 65.7 86 64 C 86 62.3 84.7 61 83 61 L 67 61 L 67 45 C 67 43.3 65.7 42 64 42 z"></path>
</svg>

          </button>
         
           {/* Change this to check the song-specific state */}
           {showPlaylistDropdown[song._id] && (
            <div ref={dropdownRef} className="dropdown-class align-middle absolute right-full translate-x-2 mt-px w-48 top-0 bg-white shadow-lg rounded-lg z-10">
            <div className="rounded-t-lg rounded-b-lg"> 
                                <p class="px-4 py-3 font-bold">Vælg playliste:</p>
                                <ul>
                                    {playlists.map((playlist) => (
                                        console.log(playlist._id), console.log(selectedSongId),
                                        <li key={playlist.id} className="px-4 py-3 hover:bg-gray-300 "  onClick={(e) => handleAddToPlaylist(e, playlist._id, song._id)}>
                                        {playlist.name}
                                    </li>
                                    ))}
                                </ul>
                                </div>
                            </div>
                        )}
          </div>
  
    </div>

    
))}




</div>
{currentSongUrl && (
    <div>
            <div class="md:hidden">
          
{/*<audio controls>
  <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" type="audio/mpeg"/>
  Your browser does not support the audio element.
  </audio> USE THIS FOR DEBUGGING AUDIO*/} 

        
            </div>
        
            <div class="!bg-gradient-to-r from-purple-600 to-blue-600 flex flex-row mt-4 w-full fixed bottom-0 z-10 pb-2 md:pb-0">
              <div class="hidden md:block md:min-w-40 lg:min-w-80">
              
                <h1 class="md:text-center text-white font-bold">{currentSongName}</h1>
                <h1 class="md:text-center text-stone-400">{currentSongAuthor}</h1>
              </div>
          <ReactH5AudioPlayer
            src={currentSongUrl}
            showSkipControls={true}
            header={true}
            autoPlay={false}
            controls
            layout="stacked-reverse"
            className="!bg-transparent !shadow-none"
            // Additional props and event handlers
      /></div>
  </div>
)}


        </div>

        
    );
}


export default Salmer;