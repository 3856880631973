// authService.js

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const register = async (email, password) => {
    try {
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
            credentials: 'include',

        });

        const data = await response.json(); // Always parse the JSON response

        if (!response.ok) {
            throw new Error(data.error || 'Error registering');
        }

        return data; // Return the successful response data
    } catch (error) {
        // Re-throw the error to be handled in the component
        throw error;
    }
};
export const login = async (email, password) => {
    try {
        const response = await fetch(`${API_BASE_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
            credentials: 'include',
        });

        if (!response.ok) {
            const data = await response.json(); // Parse the response body to get the error message
            throw new Error(data.error || 'Error logging in');
        }

        return await response.json();
    } catch (error) {
        console.error('login error:', error);
        throw error; // Make sure to re-throw the error so it can be caught by handleLogin
    }
};


export const logout = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/logout`, {
            method: 'POST',
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Error logging out');
        return await response.json();
    } catch (error) {
        console.error('logout error:', error);
        throw error;
    }
};

export const checkUserSession = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/check-session`, {
            credentials: 'include', // Important for sending the session cookie
      });
      const data = await response.json();
      return data.isLoggedIn;
    } catch (error) {
      console.error('Error checking user session:', error);
      return false; // Return false in case of an error
    }
  };
  
    

// Add more functions for other authentication operations...
